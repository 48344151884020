import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import authService from "../../services/authService";

const ProtectedRoute = ({ redirectPath = "/login" }) => {
  if (authService.getCurrentUser() == null) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
export default ProtectedRoute;
