import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + '/portalusagereport';

export function getPortalUsageReport(){
    return http.get(apiEndpoint);
}


export default{
    getPortalUsageReport
}