import React, { Component } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { NumberFilter } from "@inovua/reactdatagrid-community/NumberFilter";
import Loading from "./common/loading";
import Input from "./common/input";
import { reportNamePDRCycleGroup } from "../config.json";
import pdrService from "./../services/pdrService";
import exportQueueService from "./../services/exportQueueService";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";

class PDRCycleGroup extends Component {
  state = {
    reportLoaded: false,
    loading: false,
    submitDisabled: false,
    reportDate: "",
    columns: [],
    reportdata: [],
    filterValue: [],
  };

  handleExportToExcel = async () => {
    exportQueueService.saveExportJobQueue(
      reportNamePDRCycleGroup,
      JSON.stringify({
        ReportDate: this.state.reportDate,
      })
    );
  };

  handleReportSubmit = async () => {
    this.setState({ loading: true, reportLoaded: true, submitDisabled: true });

    const { reportDate } = this.state;
    const cycleGroupsData = await pdrService.getCycleGroups(reportDate);
    const cycleGroups = cycleGroupsData["data"];
    console.log(cycleGroups);

    const data = await pdrService.getCycleGroupsData(reportDate);
    const reportdata = data["data"];
    console.log(reportdata);

    let columns = pdrService.getDefaultGridColumns();
    let filterValue = pdrService.getDefaultGridFilters();

    cycleGroups.map((cyclegroup) => {
      columns.push({
        name: String(cyclegroup.cyclegroupid),
        header: cyclegroup.cyclegroupname,
        filterEditor: NumberFilter,
      });
      filterValue.push({
        name: String(cyclegroup.cyclegroupid),
        operator: "eq",
        type: "number",
      });
    });
    console.log(columns);

    this.setState({
      loading: false,
      reportdata,
      submitDisabled: false,
      columns,
      filterValue,
    });
  };

  render() {
    const {
      loading,
      reportDate,
      reportLoaded,
      columns,
      reportdata,
      filterValue,
      submitDisabled,
    } = this.state;
    const gridStyle = { minHeight: 550 };
    return (
      <div>
        <div className="row">
          <div className="col">
            <h4>PDR Cycle Group</h4>

            <div>
              <div className="row">
                <div className="col">
                  <Input
                    name="reportdate"
                    id="reportdate"
                    value={reportDate}
                    label="Pick Report Date: "
                    type="date"
                    onChange={(event) =>
                      this.setState({ reportDate: event.target.value })
                    }
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={submitDisabled}
                    onClick={this.handleReportSubmit}>
                    Submit
                  </button>
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
              <div className="row">
                <div className="col"></div>
                <div className="col"></div>
                <div className="col">
                  {reportLoaded && !loading && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleExportToExcel}>
                      Export To Excel
                    </button>
                  )}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  {reportLoaded && loading && <Loading />}
                  {reportLoaded && !loading && (
                    <ReactDataGrid
                      theme="default-dark"
                      style={gridStyle}
                      defaultFilterValue={filterValue}
                      idProperty="accountid"
                      columns={columns}
                      dataSource={reportdata}
                      pagination
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PDRCycleGroup;
