import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import auth from "./../services/authService";

function init(){
    Bugsnag.start({
        apiKey: '524a6522dc23060f5d4079533e221bf8',
        plugins: [new BugsnagPluginReact()],
        onError: function (event) {
            const user = auth.getCurrentUser();
            if(user != null){
                event.setUser(user.id, user.name, user.name);
            }
        }
    });
}

function logError(err){
    Bugsnag.notify(new Error(err));
}

export default{
    init,
    logError
}