import React from "react";
import Joi from "joi";
import Form from "./common/form";
import auth from "./../services/authService";
import { toast } from "react-toastify";

class LoginForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
  };

  schema_obj = {
    email: Joi.string().required().email({ tlds: { allow: false } }).label("Email"),
    password: Joi.string().required().label("Password"),
  };

  schema = Joi.object(this.schema_obj)

  componentDidMount() {
    this.schema_obj = this.loginSchema();
    this.schema = Joi.object(this.schema_obj)
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      if (!data.showSMS) {
        await auth.login(data.email, data.password);
        this.loginAction();
      } else {
        await auth.validateAuthCode(data.authcode);
        this.authCodeAction();
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  handleResendCode = async () => {
    const result = await auth.resendAuthCode();
    toast.info("New code sent!");
  };

  loginAction() {
    const { data } = this.state;
    this.schema_obj = this.codeSchema();
    this.schema = Joi.object(this.schema_obj)
    data.showSMS = true;
    this.setState({ data: { showSMS: true, authcode: "" } });
  }

  authCodeAction() {
    console.log("Goto Landing");
    window.location = "/";
  }

  loginSchema() {
    return {
      email: Joi.string().required().email({ tlds: { allow: false } }).label("Email"),
      password: Joi.string().required().label("Password"),
    };
  }

  codeSchema() {
    return {
      showSMS: Joi.boolean().label("ShowSMS"),
      authcode: Joi.number()
        .required()
        .min(100000)
        .max(999999)
        .label("Authorization Code"),
    };
  }

  render() {
    const { showSMS } = this.state.data;
    return (
      <div>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmit}>
          {!showSMS && this.renderInput("email", "Email", "email")}
          {!showSMS && this.renderInput("password", "Password", "password")}
          {!showSMS && this.renderButton("Login")}
          {showSMS &&
            this.renderInput("authcode", "Authorization Code", "authcode")}
          {showSMS && (
            <div>
              <small className="text-muted">
                Didn't recieve a security code?{" "}
                <a href="#" class="text-reset" onClick={this.handleResendCode}>
                  Resend code
                </a>
              </small>
              <br />
              <br />
            </div>
          )}
          {showSMS && this.renderButton("Verify Code")}
        </form>
      </div>
    );
  }
}

export default LoginForm;
