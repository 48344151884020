import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + '/reportdownload';

export function getReportDownloads(){
    return http.get(apiEndpoint);
}

export function saveReportDownload(reportDownload){
    return http.put(apiEndpoint, reportDownload);
}

export function downloadReportFile(reportDownload, downloadingFile){
    return http.downloadFile(apiEndpoint + "/file?id=" + reportDownload.id, reportDownload.filename, reportDownload, downloadingFile);
}

export default{
    getReportDownloads,
    saveReportDownload,
    downloadReportFile
}