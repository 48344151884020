import React, { Component } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { convertToLocalDateString } from "../utils/dateUtil";
import _ from "lodash";
import Loading from "./common/loading";
import { getPortalUsageReport } from "../services/portalUsageService";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";

class PortalUsageReport extends Component {
  state = {
    loading: true,
    portalUsage: [],
    columns: [],
    filterValue: [
      {
        name: "reportname",
        operator: "eq",
        type: "select",
        value: null,
      },
      {
        name: "username",
        operator: "eq",
        type: "select",
        value: null,
      },
    ],
  };

  async componentDidMount() {
    const data = await getPortalUsageReport();
    const portalUsage = data["data"];
    this.addLocalDate(portalUsage);
    let reports = [];
    _.sortBy(_.uniq(_.map(portalUsage, "reportname"))).map((c) =>
      reports.push({
        id: c,
        label: c,
      })
    );
    let users = [];
    _.sortBy(_.uniq(_.map(portalUsage, "username"))).map((c) =>
      users.push({
        id: c,
        label: c,
      })
    );

    const columns = [
      {
        name: "reportname",
        header: "Report Name",
        defaultFlex: 1,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: "All",
          dataSource: reports,
        },
      },
      {
        name: "username",
        header: "User Name",
        defaultFlex: 1,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: "All",
          dataSource: users,
        },
      },
      {
        name: "localaccesseddate",
        header: "Accessed Date",
        defaultFlex: 1,
        type: "date",
        sort: (p1, p2) => Date.parse(p1) - Date.parse(p2),
      },
    ];

    this.setState({ portalUsage, columns, loading: false });
  }

  addLocalDate = (reportDownloads) => {
    reportDownloads.forEach((reportdownload) => {
      reportdownload["localaccesseddate"] = convertToLocalDateString(
        reportdownload["accesseddate"]
      );
    });
  };

  render() {
    const { portalUsage, columns, filterValue, loading } = this.state;
    const gridStyle = { minHeight: 550 };
    const defaultSortInfo = { name: "tdtradeclass", dir: 1 };
    const allowUnsort = false;
    return (
      <div>
        <div className="row">
          <div className="col">
            <p>Portal Usage Report:</p>
            {loading && <Loading />}
            {!loading && (
              <ReactDataGrid
                theme="default-dark"
                style={gridStyle}
                columns={columns}
                defaultFilterValue={filterValue}
                defaultSortInfo={defaultSortInfo}
                dataSource={portalUsage}
                allowUnsort={allowUnsort}
                pagination
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PortalUsageReport;
