import React, { Component } from "react";
import { ToastContainer } from 'react-toastify';
import {Route, Routes} from 'react-router-dom';
import ProtectedRoute from './components/common/protectedRoute';
import NavBar from './components/navbar';
import OptInForm from "./components/opt-in";
import LoginForm from './components/login';
import Logout from './components/logout';
import Landing from './components/landing';
import NewStoreCriteria from './components/newstorecriteria';
import NotFound from './components/notfound';
import auth from "./services/authService";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import PDRCycleGroup from './components/pdrCycleGroup';
import PortalUsageReport from './components/portalUsageReport';


class App extends Component {
  state = {  } 
  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({user});
    console.log(user);
  }
  render() { 
    const {user} = this.state
    return (
    <React.Fragment>
      <ToastContainer/>
      {user && <NavBar user={user}/>}
      <main className="container">
        <Routes>
            <Route element={<ProtectedRoute />}>
              <Route index element={<Landing />} />
              <Route path='landing' element={<Landing />} />
              <Route path='/newstorecriteria' element={<NewStoreCriteria />}/>
              <Route path='/pdr/cyclegroup' element={<PDRCycleGroup />}/>
              <Route path='/portalusage' element={<PortalUsageReport/>}/>
              <Route path='/logout' element={<Logout />}/>
            </Route>
          <Route path='/login' element={<LoginForm />}/>
          <Route path='/opt-in' element={<OptInForm />}/>
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </main>
    </React.Fragment>
  );
  }
}
 
export default App;
