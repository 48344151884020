import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + '/newstorecriteria';

export function getNewStoreCriteria(){
    return http.get(apiEndpoint);
}

export function saveNewStoreCriteria(data){
    return http.put(apiEndpoint, data);
}