import React from "react";
import Form from "./common/form";
import { toast } from 'react-toastify'
import simpleJoi from "joi";
const Joi = simpleJoi.extend(require("joi-phone-number"));

class OptInForm extends Form {
  state = {
    data: { phone: '', is_terms_accepted: false },
    accepted: false,
    errors: {},
  };

  schema_obj = {
    phone: Joi.string().regex(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'phone number').phoneNumber({defaultCountry: 'US'}).required().label("Your phone number"),      
    is_terms_accepted: Joi.boolean()
  }
  schema = Joi.object(this.schema_obj);

  componentDidMount() {    
  }

  doSubmit = async () => {
    try {
      this.optInAction();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  optInAction() {   
    if (this.state.data.is_terms_accepted) {
      this.state.accepted = true
    } else {
      toast.info("You must click the box to agree before opting in.");
    }
  }

  render() {
    const label = 'I agree to receive text messages from Blue Ocean Software at the phone number provided above to receive OTP codes when logging in.  I understand that data rates may apply, and may reply STOP to opt out'
    return (
      <div>
        {
        !this.state.accepted && 
        <div>
          <img src="bos_logo.png" height={200} />
          <h1>Blue Ocean Software Two-Factor Authentication</h1>
          <form onSubmit={this.handleSubmit}>
            {this.renderInput("phone", "Your phone number", "text")}          
            {this.renderCheckBox("is_terms_accepted", label)}          
          { this.renderButton("Opt In")}
          </form>
        </div>
        }
        {
        this.state.accepted && 
        <div>
          <img src="bos_logo.png" height={200} />
          <h1>Blue Ocean Software Two-Factor Authentication</h1>
          <p>You have opted in successfully.  You should receive a confirmation text message.  You can reply STOP any time to opt out.</p>
        </div>
        }
      </div>
    );
  }
}

export default OptInForm;
