import React, { Component } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import BoolFilter from "@inovua/reactdatagrid-community/BoolFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { toast } from "react-toastify";
import _ from "lodash";
import Loading from "./common/loading";
import {
  getNewStoreCriteria,
  saveNewStoreCriteria,
} from "../services/newStoreCriteriaService";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";

class NewStoreCriteria extends Component {
  state = {
    loading: true,
    newStoreCriteria: [],
    columns: [],
    filterValue: [
      {
        name: "tdtradeclass",
        operator: "eq",
        type: "select",
        value: null,
      },
      {
        name: "tdsubtradeclass",
        operator: "eq",
        type: "select",
        value: null,
      },
      { name: "add", operator: "eq", type: "bool" },
      {
        name: "volumethreshold",
        operator: "eq",
        type: "number",
      },
      { name: "volumeweeks", operator: "eq", type: "number" },
    ],
  };

  async componentDidMount() {
    const data = await getNewStoreCriteria();
    const newStoreCriteria = data["data"];
    let tdTradeClasses = [];
    _.sortBy(_.uniq(_.map(newStoreCriteria, "tdtradeclass"))).map((c) =>
      tdTradeClasses.push({
        id: c,
        label: c,
      })
    );
    let tdSubTradeClasses = [];
    _.sortBy(_.uniq(_.map(newStoreCriteria, "tdsubtradeclass"))).map((c) =>
      tdSubTradeClasses.push({
        id: c,
        label: c,
      })
    );

    const columns = [
      {
        name: "tdtradeclass",
        header: "TD Trade Class",
        defaultFlex: 1,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: "All",
          dataSource: tdTradeClasses,
        },
      },
      {
        name: "tdsubtradeclass",
        header: "TD Sub Trade Class",
        defaultFlex: 1,
        filterEditor: SelectFilter,
        filterEditorProps: {
          placeholder: "All",
          dataSource: tdSubTradeClasses,
        },
      },
      {
        name: "add",
        header: "Add",
        defaultFlex: 1,
        type: "bool",
        filterEditor: BoolFilter,
        editable: true,
      },
      {
        name: "volumethreshold",
        header: "Volume Threshold",
        defaultFlex: 1,
        type: "number",
        filterEditor: NumberFilter,
        editable: true,
      },
      {
        name: "volumeweeks",
        header: "Volume Weeks",
        defaultFlex: 1,
        type: "number",
        filterEditor: NumberFilter,
        editable: true,
      },
    ];

    this.setState({ newStoreCriteria, columns, loading: false });
  }

  onEditComplete = async ({ value, columnId, rowId }) => {
    const data = [...this.state.newStoreCriteria];
    let editedRow = _.filter(data, function (row) {
      return row["id"] === rowId;
    })[0];
    console.log(editedRow);
    editedRow[columnId] = value;
    console.log(editedRow);
    await saveNewStoreCriteria(editedRow);
    toast.info("Saved successfully!");
  };

  render() {
    const { newStoreCriteria, columns, filterValue, loading } = this.state;
    const gridStyle = { minHeight: 550 };
    const defaultSortInfo = { name: "tdtradeclass", dir: 1 };
    return (
      <div>
        <div className="row">
          <div className="col">
            <p>Available New Store Criterias:</p>
            {loading && <Loading />}
            {!loading && (
              <ReactDataGrid
                theme="default-dark"
                style={gridStyle}
                idProperty="id"
                defaultFilterValue={filterValue}
                columns={columns}
                defaultSortInfo={defaultSortInfo}
                dataSource={newStoreCriteria}
                onEditComplete={this.onEditComplete}
                pagination
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NewStoreCriteria;
