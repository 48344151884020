import http from "./httpService";
import { apiUrl } from "../config.json";
import StringFilter from "@inovua/reactdatagrid-community/StringFilter";
import PDRCycleGroup from './../components/pdrCycleGroup';

const apiEndpoint = apiUrl + '/distributionreport';

export function getCycleGroups(reportdate){
    return http.post(apiEndpoint + "/cyclegroups", {reportdate});
}
export function getCycleGroupsData(reportdate){
    return http.post(apiEndpoint + "/cyclegroupsdata", {reportdate});
}

export function getDefaultGridColumns(){
    return [
      {
        name: "sfaccountid",
        header: "SF Acct ID",
        filterEditor: StringFilter,
      },
      {
        name: "accountid",
        header: "Account ID",
        filterEditor: StringFilter,
      },
      {
        name: "accountname",
        header: "Account Name",
        filterEditor: StringFilter,
      },
      {
        name: "street",
        header: "Street",
        filterEditor: StringFilter,
      },
      {
        name: "city",
        header: "City",
        filterEditor: StringFilter,
      },
      {
        name: "state",
        header: "State",
        filterEditor: StringFilter,
      },
      {
        name: "zip",
        header: "Zip",
        filterEditor: StringFilter,
      },
      {
        name: "workordernumber",
        header: "WO Number",
        filterEditor: StringFilter,
      },
      {
        name: "enddate",
        header: "WO End Date",
        filterEditor: StringFilter,
      },
      {
        name: "calldate",
        header: "Last Call Date",
        filterEditor: StringFilter,
      },
      {
        name: "lastcall",
        header: "Last Call Flag",
        filterEditor: StringFilter,
      },
      {
        name: "calltype",
        header: "Call Type",
        filterEditor: StringFilter,
      },
      {
        name: "workorderownername",
        header: "WO Owner Name",
        filterEditor: StringFilter,
      },
      {
        name: "workorderterritory",
        header: "WO Owner Terr",
        filterEditor: StringFilter,
      },
      {
        name: "accountownername",
        header: "Acct Owner Name",
        filterEditor: StringFilter,
      },
      {
        name: "accountownerterritory",
        header: "Acct Owner Terr",
        filterEditor: StringFilter,
      },
    ]
}

export function getDefaultGridFilters(){
    return [
      {
        name: "sfaccountid",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "accountid",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "accountname",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "street",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "city",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "state",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "zip",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "workordernumber",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "enddate",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "calldate",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "lastcall",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "calltype",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "workorderownername",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "workorderterritory",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "accountownername",
        operator: "startsWith",
        type: "string",
        value: "",
      },
      {
        name: "accountownerterritory",
        operator: "startsWith",
        type: "string",
        value: "",
      },
    ];
}

export default{
    getCycleGroups,
    getCycleGroupsData,
    getDefaultGridColumns,
    getDefaultGridFilters
}