import http from "./httpService";
import { apiUrl } from "../config.json";
const apiEndpoint = apiUrl + '/exportqueue';

export function saveExportJobQueue(jobname, params){
    return http.post(apiEndpoint, {jobname, params});
}

export default{
    saveExportJobQueue
}