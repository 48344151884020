import React, { Component } from "react";
import reportDownloadService from "./../services/reportDownloadService";
import exportQueueService from "./../services/exportQueueService";
import auth from "./../services/authService";
import Table from "./common/table";
import Loading from "./common/loading";
import { convertToLocalDateString } from "../utils/dateUtil";
import _ from "lodash";
import roles from "../entities/roles";

class Landing extends Component {
  state = {
    loading: true,
    downloadInprogress: false,
    reportDownloads: [],
    columns: [
      {
        path: "category",
        label: "Category",
      },
      {
        path: "name",
        label: "Name",
      },
      {
        key: "lastmodified",
        label: "Last Modified",
        content: (reportDownload) => {
          const lastModified = new Date(reportDownload["locallastmodified"]);
          const currentDate = new Date();
          const isRecent = (currentDate - lastModified) / 1000 / 60 / 60 <= 2;
          return (
            <span>
              {reportDownload["locallastmodified"]}{" "}
              {isRecent && <span className="badge bg-secondary">Recent</span>}
            </span>
          );
        },
      },
      {
        key: "refresh",
        content: (reportDownload) =>
          auth.getCurrentUserRole() == roles.Roles.Admin &&
          ((reportDownload["exportjobqueueid"] !== null && (
            <span>Currently being refreshed.</span>
          )) ||
            (reportDownload["canrefresh"] === true && (
              <div class="container">
                <div class="row">
                  <div className="col">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.handleRefresh(reportDownload)}>
                      Refresh {reportDownload["exportjobqueueid"]}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div className="col">
                    <small className="text-muted">
                      Refresh Time: {reportDownload["averagerefreshtime"]}
                    </small>
                  </div>
                </div>
              </div>
            ))),
      },
      {
        key: "newdownload",
        content: (reportDownload) => {
          if (reportDownload["downloading"] != true)
            return (
              <button
                className="btn btn-primary"
                onClick={() => this.handleDownload(reportDownload)}>
                Download
              </button>
            );
          else
            return (
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: reportDownload["percentcomplete"] }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100">
                  {reportDownload["percentcomplete"]}%
                </div>
              </div>
            );
        },
      },
    ],
    sortColumn: { path: "category", order: "asc" },
  };

  componentDidMount() {
    this.refreshReports();
  }

  refreshReports = async () => {
    if (!this.state.downloadInprogress) {
      const data = await reportDownloadService.getReportDownloads();
      const reportDownloads = data["data"];
      this.addLocalDate(reportDownloads);
      this.setState({ reportDownloads, loading: false });
    }
    setTimeout(() => this.refreshReports(), 10000);
  };

  downloadingFile = (reportDownload) => {
    if (reportDownload["percentcomplete"] >= 100) {
      this.state.downloadInprogress = false;
      reportDownload["downloading"] = false;
    }
    const reports = [...this.state.reportDownloads];
    const index = this.getReportDownloadIndex(reportDownload);
    reports[index] = { ...reportDownload };
    this.setState({ reportDownloads: reports });
  };

  handleDownload = (reportDownload) => {
    this.state.downloadInprogress = true;
    reportDownload["downloading"] = true;
    reportDownload["percentcomplete"] = 0;
    const reports = [...this.state.reportDownloads];
    const index = this.getReportDownloadIndex(reportDownload);
    reports[index] = { ...reportDownload };
    this.setState({ reportDownloads: reports });
    reportDownloadService.downloadReportFile(
      reportDownload,
      this.downloadingFile
    );
  };

  handleRefresh = async (reportDownload) => {
    const result = await exportQueueService.saveExportJobQueue(
      reportDownload["exportjobname"],
      null
    );

    reportDownload["exportjobqueueid"] = result["data"]["saveexportjobqueue"];
    const reports = [...this.state.reportDownloads];
    const index = this.getReportDownloadIndex(reportDownload);
    reports[index] = { ...reportDownload };
    this.setState({ reportDownloads: reports });

    reportDownloadService.saveReportDownload(reportDownload);
  };

  getReportDownloadIndex = (reportDownload) => {
    const reports = [...this.state.reportDownloads];
    let index = 0;
    let foundIndex = -1;
    reports.forEach((value) => {
      if (value["name"] === reportDownload["name"]) foundIndex = index;
      index++;
    });
    return foundIndex;
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  addLocalDate = (reportDownloads) => {
    reportDownloads.forEach((reportdownload) => {
      reportdownload["locallastmodified"] = convertToLocalDateString(
        reportdownload["lastmodified"]
      );
    });
  };

  render() {
    const { reportDownloads, columns, sortColumn, loading } = this.state;
    const sortedReportDownloads = _.orderBy(
      reportDownloads,
      [sortColumn.path],
      [sortColumn.order]
    );
    return (
      <div className="row">
        <div className="col">
          <p>Available reports to download</p>
          {loading && <Loading />}
          {!loading && (
            <Table
              columns={columns}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              data={sortedReportDownloads}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Landing;
