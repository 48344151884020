import jwtDecode from 'jwt-decode';
import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + '/auth';
const tokenKey = 'token';
http.setJwt(getJwt());

async function login(email, password){
    const {data: jwt} = await http.post(apiEndpoint, {email, password});
    localStorage.setItem(tokenKey, jwt);
    http.setJwt(getJwt());
}

function validateAuthCode(authcode){
    return http.post(apiEndpoint + "/authcode", {authcode});
}

function resendAuthCode(){
    return http.post(apiEndpoint + "/resendcode");
}

async function logout(){
    localStorage.removeItem(tokenKey);
}

function getCurrentUser(){
try{
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
    
    }
    catch(ex){
        return null;
    }
}

function getCurrentUserRole(){
    return getCurrentUser()["role"];
}

function getJwt(){
    return localStorage.getItem(tokenKey);
}

export default{
    login,
    logout,
    validateAuthCode,
    resendAuthCode,
    getCurrentUser,
    getCurrentUserRole,
    getJwt
}