import axios from "axios";
import { toast } from "react-toastify";
import logger from "./logService";
const qs = require('qs');



axios.interceptors.response.use(function (response) {
    console.log(response.status);
    return response;
}, function (error) {
    logger.logError(error);
    toast.error("An unexpected error occurred.");
    return Promise.reject(error);
});

function setJwt(jwt){
    axios.defaults.headers.common['x-auth-token'] = jwt;
}

export default {
    get: axios.get,
    post: (endpoint, data) => {
        return axios.post(endpoint, data, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                            });
                        },    
    put: axios.put,
    delete: axios.delete,
    setJwt,
    downloadFile: (endpoint, filename, reportdownload, downloadingFile) => {
        return axios({
                        url: endpoint,
                        method: 'GET',
                        responseType: 'blob', // important
                        onDownloadProgress: (progressEvent) => {
                             let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); 
                             reportdownload["percentcomplete"] = percentCompleted;
                             downloadingFile(reportdownload);
                        }
                        }).then(response => {    
                                    const url = window.URL.createObjectURL(new Blob([response.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', filename); //or any other extension
                                    document.body.appendChild(link);
                                    link.click();
                        });
    }
};