import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

class NavBar extends Component {
  state = {};
  render() {
    const { user } = this.props;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            TPB Portal
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {!user && (
                <li className="nav-item">
                  <NavLink className="nav-link" to="/login">
                    Login
                  </NavLink>
                </li>
              )}
              {user && (
                   <React.Fragment>
                     {user.role === 'Admin' && (
                     <li className={"nav-item"}>
                         <NavLink className={"nav-link"} to={"/portalusage"}>
                           Portal Usage
                         </NavLink>
                     </li>
                     )}
                     <li className="nav-item">
                        <NavLink className="nav-link" to="/logout">
                          Logout
                        </NavLink>
                      </li>
                   </React.Fragment>
                   )}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
